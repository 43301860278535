import dayjs from 'dayjs';

import { defaultDateFormat } from '@/configs/app';
import axios from '@/libs/axios';
import {
  AdminLoginResponseType,
  AdminProfileType,
  AdminUserType,
} from '@/types/api/admin-users.type';
import { ContactPersonType, ContactPersonUpdateDataType } from '@/types/api/contact-persons.type';
import {
  DeliveryDataType,
  DeliveryDateType,
  DeliveryUpdateDataType,
} from '@/types/api/delivery.type';
import { EmailTemplateType, EmailTemplateUpdateDataType } from '@/types/api/email-template.type';
import {
  ProductCategoryCreateType,
  ProductCategoryStatusType,
  ProductCategoryType,
  ProductCategoryUpdateType,
} from '@/types/api/product-categories.type';
import { ProductType } from '@/types/api/products.type';
import { UserCreateDataType, UserLoginResponse, UserType } from '@/types/api/users.type';

/**
 * Types
 */
export type MoveDirectionType = 'move-up' | 'move-down';
type SimpleResponseWithMessage = { message: string };

/**
 * Constants
 */
const extractData = <T>(res: { data: T }) => res.data;

/**
 * TODO:
 * - type the errors as well and make sure to handle them in the components
 */
export const API_Actions = {
  auth: {
    login: (token: string) =>
      axios.post<AdminLoginResponseType>('/api/auth/admin-login', { token }).then(extractData),
  },
  users: {
    list: () => axios.get<UserType[]>('/api/admin/users').then(extractData),
    sync: () => axios.post('/api/admin/users/sync').then(extractData),
    syncId: (id: number) => axios.put(`/api/admin/users/${id}/sync`).then(extractData),
    loginAs: (id: number) =>
      axios.post<UserLoginResponse>(`/api/admin/users/${id}/login-as`).then(extractData),
    profile: () => axios.get<AdminProfileType>('/api/admin/admin-users/profile').then(extractData),
    logout: () =>
      axios.post<SimpleResponseWithMessage>('/api/admin/admin-users/logout').then(extractData),
    get: (id: number) => axios.get<UserType>(`/api/admin/users/${id}`).then(extractData),
    create: (data: UserCreateDataType) =>
      axios.post<UserType>('/api/admin/users', data).then(extractData),
    update: (id: number, data: UserCreateDataType) =>
      axios.put<UserType>(`/api/admin/users/${id}`, data).then(extractData),
    delete: (id: number) =>
      axios.delete<SimpleResponseWithMessage>(`/api/admin/users/${id}`).then(extractData),
    restore: (id: number) =>
      axios.put<SimpleResponseWithMessage>(`/api/admin/users/${id}/restore`).then(extractData),
    resetPassword: (id: number) =>
      axios.post(`/api/admin/users/${id}/reset-password`).then(extractData),
  },
  contacts: {
    list: () => axios.get<ContactPersonType[]>('/api/admin/contact-persons').then(extractData),
    import: (data: any) => axios.post('/api/admin/contact-persons/import', data).then(extractData),
    export: () =>
      axios
        .get('/api/admin/contact-persons/export', { responseType: 'blob' /* 'arraybuffer' */ })
        .then((res) => {
          const baseFilename =
            res.headers['content-disposition'].split('filename=')[1] || 'contact_persons.xlsx';
          const filename = `${dayjs().format(defaultDateFormat)}-MideaWarehouse_${baseFilename}`;

          const url = URL.createObjectURL(res.data); // const blob = new Blob([res.data], { type: res.data.type || res.headers['content-type'] });
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);

          return res.data;
        }),
    get: (id: number) =>
      axios.get<ContactPersonType>(`/api/admin/contact-persons/${id}`).then(extractData),
    create: (data: ContactPersonUpdateDataType) =>
      axios.post<ContactPersonType>('/api/admin/contact-persons', data).then(extractData),
    update: (id: number, data: ContactPersonUpdateDataType) =>
      axios.put<ContactPersonType>(`/api/admin/contact-persons/${id}`, data).then(extractData),
    delete: (id: number) => axios.delete(`/api/admin/contact-persons/${id}`).then(extractData),
    restore: (id: number) =>
      axios
        .put<SimpleResponseWithMessage>(`/api/admin/contact-persons/${id}/restore`)
        .then(extractData),
  },
  emails: {
    list: () => axios.get<EmailTemplateType[]>('/api/admin/email-templates').then(extractData),
    get: (id: number) =>
      axios.get<EmailTemplateType>(`/api/admin/email-templates/${id}`).then(extractData),
    update: (data: EmailTemplateUpdateDataType) =>
      axios.put<EmailTemplateType>(`/api/admin/email-templates/${data.id}`, data).then(extractData),
  },
  categories: {
    list: () => axios.get<ProductCategoryType[]>('/api/admin/product-categories').then(extractData),
    create: (data: ProductCategoryCreateType) =>
      axios.post<ProductCategoryType>('/api/admin/product-categories', data).then(extractData),
    update: (data: ProductCategoryUpdateType | FormData) => {
      const isFormData = data instanceof FormData;
      const contentType = isFormData ? 'multipart/form-data' : 'application/json';

      return axios
        .put<SimpleResponseWithMessage>(
          `/api/admin/product-categories/${isFormData ? data.get('id') : data.id}`,
          data,
          {
            headers: {
              'Content-Type': contentType,
            },
          }
        )
        .then(extractData);
    },
    delete: (id: number) =>
      axios
        .delete<SimpleResponseWithMessage>(`/api/admin/product-categories/${id}`)
        .then(extractData),
    status: (data: { id: number; status: ProductCategoryStatusType }) =>
      axios
        .put<SimpleResponseWithMessage>(
          `/api/admin/product-categories/${data.id}/status/${data.status}`
        )
        .then(extractData),
    move: (data: { id: number; direction?: MoveDirectionType }) =>
      axios
        .put(`/api/admin/product-categories/${data.id}/${data.direction ?? 'move-down'}`)
        .then(extractData),
    get: (id: number) =>
      axios.get<ProductCategoryType>(`/api/admin/product-categories/${id}`).then(extractData),
  },
  products: {
    list: () => axios.get<ProductType[]>('/api/admin/products').then(extractData),
    get: (id: number) => axios.get<ProductType>(`/api/admin/products/${id}`).then(extractData),
    sync: () => axios.post('/api/admin/products/sync').then(extractData),
    syncId: (id: number) => axios.post(`/api/admin/products/${id}/sync`).then(extractData),
    saleable: async ({ id, value }: { id: number; value: boolean }) =>
      axios
        .patch<SimpleResponseWithMessage>(`/api/admin/products/${id}/saleable/${value}`)
        .then(extractData),
  },
  delivery: {
    get: () => axios.get<DeliveryDateType[]>('/api/admin/calendar').then(extractData),
    settings: () => axios.get<DeliveryDataType>('/api/admin/calendar/settings').then(extractData),
    update: (data: DeliveryUpdateDataType) =>
      axios
        .patch<SimpleResponseWithMessage>('/api/admin/calendar/settings', data)
        .then(extractData),
    sync: () => axios.post<SimpleResponseWithMessage>('/api/admin/calendar/sync').then(extractData),
  },
  admins: {
    list: () => axios.get<AdminUserType[]>('/api/admin/admin-users').then(extractData),
    sync: () =>
      axios.post<SimpleResponseWithMessage>('/api/admin/admin-users/sync').then(extractData),
  },
};
